<template>
  <div class="content">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row ">
      <div class="col-12 col-md-12">
        <table class="tax-table table table-hover align-middle table-clickable">
          <thead>
          <tr>
            <th>
              Moeda
            </th>
            <th>
              Pagamento
            </th>
            <th>
              Recebimento
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(divergencia, index) in getDivergencias" v-bind:key="index">
            <td>{{ divergencia.moeda }}</td>
            <td>{{ $util.formatNumber(divergencia.pagamento, 2) }}</td>
            <td>{{ $util.formatNumber(divergencia.recebimento, 2) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="!this.observacaoList || observacaoList <= 0">
      Nenhuma observação informada
    </div>
    <div v-else>
      <div class="process-observacao" v-for="(item, index) in observacaoList" v-bind:key="index">
        <div>
          <span class="usuario">{{ $util.formatUser(item.email) }}</span>
          <span class="criado_em">{{ $util.formatDateTime(item.data) }}</span>
        </div>
        <div class="observacao" v-html="$util.nl2br(item.observacao)"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'
// import ReportService from '@/services/ReportService'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ReportService from '@/services/ReportService'

export default {
  name: 'ReportAlertsProcessosCMFFretePrejuizoDetails',
  data () {
    return {
      tipoAlerta: '09206',
      isLoading: false,
      fullPage: false,
      observacaoList: []
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    },
    options: {
      type: Object
    }
  },
  components: {
    Loading
  },
  created () {
    let _this = this
    _this.isLoading = true
    ReportService.getInfosAlert(_this.tipoAlerta, _this.rowData.IdLogistica_House).then(res => {
      this.observacaoList = res.data.data
    }
    ).finally(() => {
      _this.isLoading = false
    })
  },
  computed: {
    getDivergencias () {
      let divergencias = []

      if (this.rowData.dados_divergentes) {
        let divergenciaInfo = this.rowData.dados_divergentes.split(';')

        for (let i in divergenciaInfo) {
          let divergencia = divergenciaInfo[i].split(',')
          divergencias.push({
            moeda: divergencia[0],
            pagamento: divergencia[1],
            recebimento: divergencia[3]
          })
        }
      }

      return divergencias
    }
  }
}
</script>

<style scoped>
  .content {
    margin-top: 5px;
    margin-bottom: 20px;
  }
</style>

<style>
.process-observacao .usuario, .process-observacao .criado_em {
  font-size: 12px;
}
.process-observacao .criado_em {
  padding-left: 8px;
  font-size: 12px;
}

.process-observacao .observacao {
  padding: 5px 0 15px 0;
  font-size: 14px;
  padding-bottom: 25px;
}
</style>
